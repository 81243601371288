/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestApi } from "../lib/http";

export const Batch = (url: string): any => {
  return {
    createBatch: (payload: any, programId: number) => {
      const urlPath = `${url}/programs/${programId}/batches`;

      return requestApi({
        method: "POST",
        path: urlPath,
        body: payload,
      });
    },
    deleteBatch: (id: number) => {
      const urlPath = `${url}/batches/${id}`;

      return requestApi({
        method: "DELETE",
        path: urlPath,
      });
    },
    editBatch: (payload: any, batchId: number) => {
      const urlPath = `${url}/batches/${batchId}`;

      return requestApi({
        method: "PUT",
        path: urlPath,
        body: payload,
      });
    },
  };
};
