import React from "react";
import "./App.css";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import { Switch, useRouteMatch } from "react-router";
import NotFoundPage from "./pages/error";
import GlobalUIComponent from "./components/GlobalUIComponent";
import ProgramDetailPage from "./pages/program/detail";
import requireLogin from "./lib/middleware";

const LoginPage = React.lazy(() => import("./pages/login"));
const ConfigPage = React.lazy(() => import("./pages/configs"));
const ProgramPage = React.lazy(() => import("./pages/program"));
const ReportPage = React.lazy(() => import("./pages/report"));

const App: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="App">
      <GlobalUIComponent />
      <GuardProvider error={NotFoundPage}>
        <React.Suspense fallback={<div></div>}>
          <Switch>
            <GuardedRoute path={`${match.url}`} exact component={LoginPage} />
            <GuardedRoute path={`${match.url}config`} exact component={ConfigPage} guards={[requireLogin]} />
            <GuardedRoute path={`${match.url}program`} exact component={ProgramPage} guards={[requireLogin]} />
            <GuardedRoute path={`${match.url}program/:id`} exact component={ProgramDetailPage} guards={[requireLogin]} />
            <GuardedRoute path={`${match.url}report`} exact component={ReportPage} guards={[requireLogin]} />
            <GuardedRoute component={NotFoundPage} />
          </Switch>
        </React.Suspense>
      </GuardProvider>
    </div>
  );
};

export default App;
