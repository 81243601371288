/* eslint-disable @typescript-eslint/no-explicit-any */
import { Asset } from "./asset";
import { Auth } from "./auth";
import { Batch } from "./batch";
import { Config } from "./config";
import { Program } from "./program";
import { Report } from "./report";

const URL = process.env.REACT_APP_BASE_URL ?? "https://localhost:8000";

export const AuthApi = Auth(URL);
export const ConfigApi = Config(URL);
export const AssetApi = Asset(URL);
export const ProgramApi = Program(URL);
export const BatchApi = Batch(URL);
export const ReportApi = Report(URL);
