import { requestApi } from "../lib/http";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const Auth = (url: string): any => {
  return {
    login: async (payload: any) => {
      const path = `${url}/admin/login`;
      return requestApi({
        method: "POST",
        path: path,
        body: payload,
      });
    },
  };
};
