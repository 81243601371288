/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BatchApi, ProgramApi } from "../../../api";
import { DangerButton, PrimaryButton } from "../../../components/Button";
import Navigation from "../../../components/Navigation";
import { useModalStore } from "../../../hooks/store";
import AlertSwal from "../../../lib/AlertSwal";

interface Param {
  id?: string;
}

const ProgramDetail: React.FC = () => {
  const { showCreateModal, hideCreateModal, showEditModal, hideEditModal, showCreateReportModal, hideCreateReportModal } = useModalStore(
    state => ({
      showCreateModal: state.setShowCreateBatchModal,
      hideCreateModal: state.setHideCreateBatchModal,
      showEditModal: state.setShowEditBatchModal,
      hideEditModal: state.setHideEditBatchModal,
      showCreateReportModal: state.setShowCreateReportModal,
      hideCreateReportModal: state.setHideCreateReportModal,
    }),
  );
  const history = useHistory();

  const { id } = useParams<Param>();
  const [program, setProgram] = useState({
    id: 1,
    name: "hello",
    program_type: "donation",
    batches: [
      {
        id: 1,
        name: "world",
        description: "hello world!",
        banner_url: "",
        start_date: "Fri Oct 15 2021 00:25:15 GMT+0700",
        end_date: "Fri Oct 15 2021 00:25:15 GMT+0700",
        confirmation_link: "link",
      },
    ],
  });

  const updateProgram = () => {
    AlertSwal.showLoading();

    ProgramApi.updateProgram(id, { name: program.name, program_type: program.program_type })
      .then((_res: any) => {
        AlertSwal.showSuccessMessage();
      })
      .catch(() => AlertSwal.showErrorMessage());
  };

  const fetchPrograms = () => {
    ProgramApi.getDetailProgram(id)
      .then((res: any) => {
        setProgram(res);
      })
      .catch(() => {
        history.goBack();
        AlertSwal.showErrorMessage("Failed to get Detail Programs");
      });
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const onClickCreateBatch = () => {
    showCreateModal(id, valid => {
      if (valid) {
        hideCreateModal();
        fetchPrograms();
      }
    });
  };

  const deleteBatch = (batchId: number) => {
    AlertSwal.confirmation({}).then((res: any) => {
      if (res.value) {
        AlertSwal.showLoading();

        BatchApi.deleteBatch(batchId)
          .then(() => {
            fetchPrograms();
            AlertSwal.showSuccessMessage();
          })
          .catch(() => AlertSwal.showErrorMessage());
      }
    });
  };

  const onClickEditBatch = (item: any) => {
    showEditModal(valid => {
      if (valid) {
        hideEditModal();
        fetchPrograms();
      }
    }, item);
  };

  const onClickCreateReport = (batchId: number) => {
    showCreateReportModal(batchId, valid => {
      if (valid) {
        hideCreateReportModal();
      }
    });
  };

  const sanitizeLink = (path: string) => {
    if (path.includes("https://") || path.includes("http://")) {
      return path;
    }

    return `https://${path}`;
  };

  return (
    <div className="h-full min-h-screen bg-gray-100">
      <Navigation />
      <section className="flex justify-center items-center mt-16 flex-col">
        <div className="bg-white p-5 shadow-lg max-w-screen-xl w-full text-left">
          <div className="text-xl">Program</div>
          <div className="mt-8">
            <div className="w-1/2">
              <label className="font-bold">Name</label>
              <input
                type="text"
                className="input-form"
                value={program.name}
                onChange={e => setProgram({ ...program, name: e.target.value })}
              />
            </div>
            <div className="w-1/2 mt-3">
              <label className="font-bold">Type</label>
              <div className="capitalize">{program.program_type}</div>
            </div>
            <div className="mt-4">
              <PrimaryButton onClick={updateProgram}>Update Program</PrimaryButton>
            </div>
          </div>
        </div>
        <div className="bg-white p-5 shadow-lg max-w-screen-xl w-full text-left mt-4">
          <div className="text-xl mb-3">Batch</div>
          <PrimaryButton onClick={onClickCreateBatch}>Create Batch</PrimaryButton>
          <div className="mt-3">
            {program.batches.length > 0 && (
              <>
                {program.batches.map((data, index) => {
                  return (
                    <div key={index} className="mb-4 p-3 shadow max-w-screen w-full">
                      <div className="flex w-full justify-between">
                        <div>
                          <div className="flex gap-x-3">
                            <img style={{ height: "10rem" }} src={data.banner_url} alt="banner" />
                            <div>
                              <div className="mt-1">{data.name}</div>
                              <div className="mt-1">
                                <div className="font-bold">Start Time - End Time</div>
                                {moment(data.start_date).format("DD MMMM YYYY")} - {moment(data.end_date).format("DD MMMM YYYY")}
                              </div>
                              <div className="mt-1">
                                <div className="font-bold">Description</div>
                                {data.description}
                              </div>
                              <div className="mt-1">
                                <div className="font-bold">Confirmation Link</div>
                                <a href={sanitizeLink(data.confirmation_link)} className="text-blue-500" target="_blank" rel="noreferrer">
                                  {data.confirmation_link}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="mt-1">
                            <PrimaryButton onClick={() => onClickCreateReport(data.id)}>Add Report</PrimaryButton>
                          </div>
                        </div>
                        <div className="flex gap-x-3">
                          <div>
                            <PrimaryButton onClick={() => onClickEditBatch(data)}>Edit</PrimaryButton>
                          </div>
                          <div>
                            <DangerButton onClick={() => deleteBatch(data.id)}>Delete</DangerButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProgramDetail;
