import React, { useState } from "react";
import { AssetApi, ReportApi } from "../../api";
import { useModalStore } from "../../hooks/store";
import AlertSwal from "../../lib/AlertSwal";
import BaseModal from "../BaseModal";
import { PrimaryButton } from "../Button";
import UploadImage from "../UploadImage";

const CreateReportModal: React.FC = () => {
  const { batchID, callback, hide } = useModalStore(state => ({
    batchID: state.selectedBatchID,
    callback: state.callBackCreateReportModal,
    hide: state.setHideCreateReportModal,
  }));
  const [form, setForm] = useState({
    link: null,
    cover_image: null,
  });
  const [error, setError] = useState({
    link: "",
    cover_image: "",
  });

  const validateForm = () => {
    let valid = false;
    const errorMessage = {
      link: "",
      cover_image: "",
    };

    if (form.link == null) {
      valid = true;
      errorMessage.link = "File is required";
    }

    if (form.cover_image == null) {
      valid = true;
      errorMessage.cover_image = "Cover is required";
    }

    return {
      errorMessage,
      valid,
    };
  };

  const submit = async () => {
    const { errorMessage, valid } = validateForm();
    setError(errorMessage);

    if (valid) {
      return;
    }

    AlertSwal.showLoading();

    try {
      const formDataFile = new FormData();
      const formDataCover = new FormData();
      formDataFile.append("file", form.link);
      formDataCover.append("file", form.cover_image);

      const resFile = await AssetApi.upload(formDataFile);
      const resCover = await AssetApi.upload(formDataCover);

      form.link = resFile.url;
      form.cover_image = resCover.url;

      await ReportApi.createReport(batchID, form);

      AlertSwal.showSuccessMessage();
      callback(true);
    } catch (e) {
      AlertSwal.showErrorMessage("failed to create report");
      callback(false);
    }
  };

  return (
    <BaseModal onBackdropClick={hide}>
      <div className="float-right cursor-pointer" onClick={hide}>
        X
      </div>
      <div className="text-lg">Create Report</div>
      <div className="text-left mt-2">
        <div className="mt-2">
          <label>File Report</label>
          <div className="mt-1">
            <input type="file" onChange={e => setForm({ ...form, link: e.target.files[0] })} />
          </div>
          <div className="text-red-500">{error.link}</div>
        </div>
        <div className="mt-2">
          <label>Cover</label>
          <UploadImage src={form.cover_image} onChange={e => setForm({ ...form, cover_image: e })}></UploadImage>
          <div className="text-red-500">{error.cover_image}</div>
        </div>
        <div className="mt-2">
          <PrimaryButton onClick={submit}>Submit</PrimaryButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateReportModal;
