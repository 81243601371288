import { requestApi } from "../lib/http";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const Config = (url: string): any => {
  return {
    setConfig: async (path: string, payload: any) => {
      const urlPath = `${url}/configs/${path}`;
      return requestApi({
        method: "POST",
        path: urlPath,
        body: payload,
      });
    },
    getConfig: async (path: string) => {
      const urlPath = `${url}/configs/${path}`;
      return requestApi({
        method: "GET",
        path: urlPath,
      });
    },
  };
};
