/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteAllCookies, getCookie } from "./cookie";
type RequestOption = {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  body?: Record<string, any>;
  path: string;
};

export const requestApi = async (option: RequestOption): Promise<any> => {
  const config: Record<string, any> = {
    method: option.method,
  };

  const token: string = getCookie("_ysT");
  if (token != "") {
    config["headers"] = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  if (!!option?.body && Object.keys(option?.body)?.length > 0) {
    config["body"] = JSON.stringify(option.body);
  }

  if (!!option?.body && option?.body instanceof FormData) {
    config["body"] = option.body;
    delete config["headers"]["Content-Type"];
  }

  return fetch(`${option.path}`, config).then(response => {
    if (!response.ok) {
      const err: any = new Error("HTTP status code: " + response.status);
      err.response = response;
      err.status = response.status;

      if (response.status == 401) {
        deleteAllCookies();
        document.location.href = "/";
      }
      throw err;
    }

    return response.json();
  });
};
