/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

interface UploadImageProps {
  onChange?: (data: any) => void;
  src?: any;
}

const UploadImage: React.FC<UploadImageProps> = ({ onChange, src }: UploadImageProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [localSrc, setSrc] = useState<any>(null);

  const openFile = () => {
    if (ref.current !== null) {
      ref.current.click();
    }
  };

  useEffect(() => {
    if (src instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(src);

      reader.onload = () => {
        setSrc(reader.result);
      };
      return;
    }

    setSrc(src);
  }, []);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file !== undefined) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          setSrc(reader.result);
        };

        if (onChange != undefined) {
          onChange(file);
        }
      }
    }
  };

  return (
    <div onClick={openFile} className="h-40 border border-solid cursor-pointer">
      {localSrc == null && <div className="flex justify-center items-center h-full">Click To Upload</div>}
      <input ref={ref} type="file" className="h-40" style={{ display: "none" }} accept="image/*" onChange={onChangeInput} />
      {localSrc != null && <img className="image-preview" alt="" src={localSrc} style={{ width: "100%", height: "150px" }} />}
    </div>
  );
};

export default UploadImage;
