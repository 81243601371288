import { requestApi } from "../lib/http";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const Report = (url: string): any => {
  return {
    createReport: (batchId: number, payload: any) => {
      const urlPath = `${url}/batches/${batchId}/reports`;

      return requestApi({
        method: "POST",
        path: urlPath,
        body: payload,
      });
    },
    getReports: (year: string) => {
      const urlPath = `${url}/reports?year=${year}`;

      return requestApi({
        method: "GET",
        path: urlPath,
      });
    },
    deleteReport: (id: number) => {
      const urlPath = `${url}/reports/${id}`;

      return requestApi({
        method: "DELETE",
        path: urlPath,
      });
    },
    getYearReports: () => {
      const urlPath = `${url}/report-years`;

      return requestApi({
        method: "GET",
        path: urlPath,
      });
    },
  };
};
