import React from "react";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const PrimaryButton: React.FC<ButtonProps> = ({ children, onClick }: ButtonProps) => {
  return (
    <button onClick={onClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm">
      {children}
    </button>
  );
};

export const DangerButton: React.FC<ButtonProps> = ({ children, onClick }: ButtonProps) => {
  return (
    <button onClick={onClick} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm">
      {children}
    </button>
  );
};
