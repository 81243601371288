import { GuardFunctionRouteProps, GuardToRoute, Next } from "react-router-guards/dist/types";
import { getCookie } from "./cookie";

const requireLogin = (_to: GuardToRoute, from: GuardFunctionRouteProps | null, next: Next): void => {
  const token = getCookie("_ysT");

  if (token == "") {
    from?.history.goBack();
    return;
  }

  next();
};

export default requireLogin;
