/* eslint-disable @typescript-eslint/no-explicit-any */
import create from "zustand";

interface StoreState {
  showCreateProgramModal: boolean;
  callBackCreateProgramModal: any;
  setShowCreateProgramModal: (fn: (valid: boolean) => void) => void;
  setHideCreateProgramModal: () => void;
  refetchProgramPublisher: (valid: boolean) => void;
  refetchProgramSubscriptions: boolean;

  //createBatch
  showCreateBatchModal: boolean;
  callBackCreateBatchModal: any;
  setShowCreateBatchModal: (id: string, fn: (valid: boolean) => void) => void;
  setHideCreateBatchModal: () => void;
  programId: string | null;

  //editBatch
  showEditBatchModal: boolean;
  selectedBatch: any;
  callBackEditBatchModal: any;
  setShowEditBatchModal: (fn: (valid: boolean) => void, selectedBatch: any) => void;
  setHideEditBatchModal: () => void;

  //createRecord
  showCreateReportModal: boolean;
  selectedBatchID: number | null;
  callBackCreateReportModal: any;
  setShowCreateReportModal: (id: number, fn: (valid: boolean) => void) => void;
  setHideCreateReportModal: () => void;
  refetchReportPublisher: (valid: boolean) => void;
  refetchReportSubscription: boolean;
}

export const useModalStore = create<StoreState>(set => ({
  showCreateProgramModal: false,
  callBackCreateProgramModal: null,
  setShowCreateProgramModal: (fn: (valid: boolean) => void) =>
    set({
      callBackCreateProgramModal: fn,
      showCreateProgramModal: true,
    }),
  setHideCreateProgramModal: () =>
    set({
      callBackCreateProgramModal: null,
      showCreateProgramModal: false,
    }),
  refetchProgramSubscriptions: false,
  refetchProgramPublisher: (valid: boolean) =>
    set({
      refetchProgramSubscriptions: valid,
    }),

  showCreateBatchModal: false,
  callBackCreateBatchModal: null,
  setShowCreateBatchModal: (id: string, fn: (valid: boolean) => void) =>
    set({
      showCreateBatchModal: true,
      callBackCreateBatchModal: fn,
      programId: id,
    }),
  setHideCreateBatchModal: () =>
    set({
      callBackCreateBatchModal: null,
      showCreateBatchModal: false,
    }),
  programId: null,

  showEditBatchModal: false,
  selectedBatch: null,
  callBackEditBatchModal: null,
  setShowEditBatchModal: (fn: (valid: boolean) => void, selectedBatch: any) =>
    set({
      showEditBatchModal: true,
      selectedBatch: selectedBatch,
      callBackEditBatchModal: fn,
    }),
  setHideEditBatchModal: () =>
    set({
      callBackEditBatchModal: null,
      showEditBatchModal: false,
    }),

  showCreateReportModal: false,
  callBackCreateReportModal: null,
  selectedBatchID: null,
  setShowCreateReportModal: (id: number, fn: (valid: boolean) => void) =>
    set({
      selectedBatchID: id,
      showCreateReportModal: true,
      callBackCreateReportModal: fn,
    }),
  setHideCreateReportModal: () =>
    set({
      selectedBatchID: null,
      showCreateReportModal: false,
      callBackCreateReportModal: null,
    }),
  refetchReportSubscription: false,
  refetchReportPublisher: (valid: boolean) =>
    set({
      refetchReportSubscription: valid,
    }),
}));
