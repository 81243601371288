/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useModalStore } from "../hooks/store";
import CreateBatchModal from "./Modals/CreateBatchModal";
import CreateProgramModal from "./Modals/CreateProgramModal";
import CreateReportModal from "./Modals/CreateReportModal";
import EditBatchModal from "./Modals/EditBatchModal";

const GlobalUIComponent: React.FC = () => {
  const modals: any[] = [];

  const { showCreateProgramModal, showCreateBatchModal, showEditBatchModal, showCreateReportModal } = useModalStore(state => ({
    showCreateProgramModal: state.showCreateProgramModal,
    showCreateBatchModal: state.showCreateBatchModal,
    showEditBatchModal: state.showEditBatchModal,
    showCreateReportModal: state.showCreateReportModal,
  }));

  if (showCreateProgramModal) {
    modals.push(<CreateProgramModal key="createProgramModal" />);
  }

  if (showCreateBatchModal) {
    modals.push(<CreateBatchModal key="createBatchModal"></CreateBatchModal>);
  }

  if (showEditBatchModal) {
    modals.push(<EditBatchModal key="editBatchModal"></EditBatchModal>);
  }

  if (showCreateReportModal) {
    modals.push(<CreateReportModal key="createReportModal"></CreateReportModal>);
  }

  return <>{modals}</>;
};

export default GlobalUIComponent;
