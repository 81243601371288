/* eslint-disable @typescript-eslint/no-explicit-any */
import Swal from "sweetalert2";

const showErrorMessage = (message = "Something went wrong"): any => {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message,
    didOpen: () => {
      Swal.hideLoading();
    },
  });
};

const showLoading = (): any => {
  return Swal.fire({
    title: "Loading",
    didOpen: () => {
      Swal.showLoading();
    },
    allowOutsideClick: true,
  });
};

const showSuccessMessage = (message = "Query Success"): any => {
  return Swal.fire({
    icon: "success",
    title: "Success",
    text: message,
    didOpen: () => {
      Swal.hideLoading();
    },
  });
};

interface PropsConfirmation {
  title?: string;
  message?: string;
  confirmText?: string;
}

const confirmation = ({ title, message, confirmText }: PropsConfirmation): any => {
  if (title == undefined) title = "Anda yakin?";

  if (message == undefined) message = "Anda tidak dapat mengembalikkannya lagi!";

  if (confirmText == undefined) confirmText = "Ya!";

  return Swal.fire({
    title: title,
    text: message,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: confirmText,
    cancelButtonText: "Tidak",
  });
};

export default {
  showErrorMessage,
  showLoading,
  showSuccessMessage,
  confirmation,
};
