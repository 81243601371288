import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { deleteAllCookies } from "../../lib/cookie";

const Navigation: React.FC = () => {
  const router = useHistory();

  const logout = () => {
    deleteAllCookies();
    router.push("/");
  };

  return (
    <section id="navigation" className="px-14 shadow-md h-14">
      <div className="flex w-full items-center h-14 justify-between">
        <div className="flex gap-x-3">
          <NavLink activeClassName="font-bold" to="/config" className="cursor-pointer">
            Config
          </NavLink>
          <NavLink activeClassName="font-bold" to="/program" className="cursor-pointer">
            Program
          </NavLink>
          <NavLink activeClassName="font-bold" to="/report" className="cursor-pointer">
            Report
          </NavLink>
        </div>
        <div>
          <div onClick={logout} className="cursor-pointer">
            Logout
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navigation;
