import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AssetApi, BatchApi } from "../../api";
import { useModalStore } from "../../hooks/store";
import AlertSwal from "../../lib/AlertSwal";
import BaseModal from "../BaseModal";
import { PrimaryButton } from "../Button";
import UploadImage from "../UploadImage";

const CreateBatchModal: React.FC = () => {
  const { hide, callback, id } = useModalStore(state => ({
    hide: state.setHideCreateBatchModal,
    callback: state.callBackCreateBatchModal,
    id: state.programId,
  }));
  const [form, setForm] = useState({
    name: "",
    banner_url: null,
    start_date: "",
    end_date: "",
    confirmation_link: "",
    description: "",
  });
  const [error, setError] = useState({
    name: "",
    banner_url: null,
    start_date: "",
    end_date: "",
    confirmation_link: "",
    description: "",
  });

  const validateForm = () => {
    let valid = false;
    const errorMessage = {
      name: "",
      banner_url: null,
      start_date: "",
      end_date: "",
      confirmation_link: "",
      description: "",
    };

    if (form.name == "") {
      valid = true;
      errorMessage.name = "Name is required";
    }
    if (form.start_date == "") {
      valid = true;
      errorMessage.start_date = "Start Date is required";
    }
    if (form.end_date == "") {
      valid = true;
      errorMessage.end_date = "End Date is required";
    }
    if (form.confirmation_link == "") {
      valid = true;
      errorMessage.confirmation_link = "Confirmation Link is required";
    }
    if (form.banner_url == null) {
      valid = true;
      errorMessage.banner_url = "Banner is required";
    }
    if (form.description == "") {
      valid = true;
      errorMessage.description = "Description is required";
    }

    return {
      errorMessage,
      valid,
    };
  };

  const submit = async () => {
    const { errorMessage, valid } = validateForm();
    setError(errorMessage);

    if (valid) {
      return;
    }

    const tempBannerUrl = form.banner_url;

    AlertSwal.showLoading();

    try {
      const formData = new FormData();
      formData.append("file", form.banner_url);
      const res = await AssetApi.upload(formData);

      form.banner_url = res.url;
      await BatchApi.createBatch(
        {
          name: form.name,
          banner_url: form.banner_url,
          start_date: moment(form.start_date).format("YYYY-MM-DD"),
          end_date: moment(form.end_date).format("YYYY-MM-DD"),
          confirmation_link: form.confirmation_link,
          description: form.description,
        },
        id,
      );
      AlertSwal.showSuccessMessage();
      callback(true);
    } catch (e) {
      form.banner_url = tempBannerUrl;
      AlertSwal.showErrorMessage("failed to create batch");
      callback(false);
    }
  };

  return (
    <BaseModal onBackdropClick={hide}>
      <div className="float-right cursor-pointer" onClick={hide}>
        X
      </div>
      <div className="text-lg">Create Batch</div>
      <div className="text-left">
        <div className="mt-2">
          <label>Name</label>
          <input type="text" className="input-form" value={form.name} onChange={e => setForm({ ...form, name: e.target.value })} />
          <div className="text-red-500">{error.name}</div>
        </div>
        <div className="w-2/5 mt-2">
          <label>Banner</label>
          <div className="h-3/5">
            <UploadImage src={form.banner_url} onChange={data => setForm({ ...form, banner_url: data })} />
          </div>
          <div className="text-red-500">{error.banner_url}</div>
        </div>
        <div className="mt-2">
          <label>Start Date</label>
          <input
            type="date"
            className="input-form"
            value={form.start_date}
            onChange={e => setForm({ ...form, start_date: e.target.value })}
          />
          <div className="text-red-500">{error.start_date}</div>
        </div>
        <div className="mt-2">
          <label>End Date</label>
          <input type="date" className="input-form" value={form.end_date} onChange={e => setForm({ ...form, end_date: e.target.value })} />
          <div className="text-red-500">{error.end_date}</div>
        </div>
        <div className="mt-2">
          <label>Confimation Link</label>
          <input
            type="text"
            className="input-form"
            value={form.confirmation_link}
            onChange={e => setForm({ ...form, confirmation_link: e.target.value })}
          />
          <div className="text-red-500">{error.confirmation_link}</div>
        </div>
        <div className="mt-2">
          <label>Description</label>
          <textarea className="input-form" value={form.description} onChange={e => setForm({ ...form, description: e.target.value })} />
          <div className="text-red-500">{error.description}</div>
        </div>
        <div className="mt-2">
          <PrimaryButton onClick={submit}>Submit</PrimaryButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateBatchModal;
