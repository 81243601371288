import React, { useEffect, useRef, useState } from "react";

const topMargin = 100;

interface Props {
  children: React.ReactNode;
  onBackdropClick?: () => void;
}

const BaseModal: React.FC<Props> = ({ children, onBackdropClick }: Props) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const modalHeight = ref.current.offsetHeight;
    const windowHeight = window.innerHeight;
    if (modalHeight > windowHeight - topMargin) {
      setIsOverflow(true);
    }
  }, [ref.current, window.innerHeight]);

  return (
    <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
      <div onClick={onBackdropClick} className="fixed opacity-40 inset-0 bg-gray-400"></div>
      <div
        ref={ref}
        style={isOverflow ? { overflowY: "auto", height: "80%" } : {}}
        className="bg-white shadow rounded-xl w-2/3 p-4 mt-4 z-40"
      >
        {children}
      </div>
    </div>
  );
};

export default BaseModal;
