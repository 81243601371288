/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestApi } from "../lib/http";

export const Program = (url: string): any => {
  return {
    createProgram: (payload: any) => {
      const urlPath = `${url}/programs`;

      return requestApi({
        method: "POST",
        path: urlPath,
        body: payload,
      });
    },
    getPrograms: () => {
      const urlPath = `${url}/programs?per_page=0`;

      return requestApi({
        method: "GET",
        path: urlPath,
      });
    },
    deletePrograms: (id: number) => {
      const urlPath = `${url}/programs/${id}`;

      return requestApi({
        method: "DELETE",
        path: urlPath,
      });
    },
    getDetailProgram: (id: number) => {
      const urlPath = `${url}/programs/${id}`;

      return requestApi({
        method: "GET",
        path: urlPath,
      });
    },
    updateProgram: (id: number, payload: any) => {
      const urlPath = `${url}/programs/${id}`;

      return requestApi({
        method: "PUT",
        path: urlPath,
        body: payload,
      });
    },
  };
};
